import { getEnv, getIsInApp, getIsSeller } from './config';
import { createLink, getLocaleStore, isClient, saveLocaleStore } from './core';

function factoryAppFun(fun, params) {
  let stParams = JSON.stringify(params);
  if (isInFlutter()) {
    window[fun]?.postMessage(stParams);
  } else if (isInAndroid()) {
    callBridge(fun, stParams);
  } else {
    postAppMessages(fun, stParams);
  }
}

function register(fnName, cb) {
  if (!isClient()) return;
  if (!window.wvBridgeObject) {
    window.wvBridgeObject = {};
  }
  window.wvBridgeObject[fnName] = cb;
}

function unRegister(fnName) {
  if (!isClient() || !window.wvBridgeObject) return;
  delete window.wvBridgeObject[fnName];
}

function isInApp() {
  return (
    (isClient() &&
      window.wvBridgeObject &&
      typeof window.wvBridgeObject['appUserInfo'] === 'function') ||
    isInAndroid() ||
    isInFlutter()
  );
}

function isPublicInAndroid() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  return isAndroid;
}

function isInAndroid() {
  return (
    isClient() &&
    window.android &&
    typeof window.android['appUserInfo'] === 'function'
  );
}

function isInFlutter() {
  if (isClient()) {
    let data = '';
    if (
      typeof navigator.userAgent === 'string' &&
      navigator.userAgent?.indexOf('userInfo') > -1
    ) {
      data = JSON.parse(navigator?.userAgent);
    }
    return data.userInfo;
  } else {
    return false;
  }
}

function callBridge(fnName, args) {
  if (
    isClient() &&
    window.wvBridgeObject &&
    typeof window.wvBridgeObject[fnName] === 'function'
  ) {
    return window.wvBridgeObject[fnName](args);
  }
  if (
    isClient() &&
    window.android &&
    typeof window.android[fnName] === 'function'
  ) {
    return args ? window.android[fnName](args) : window.android[fnName]();
  }
}

function postAppMessages(messageName, message) {
  window.webkit?.messageHandlers?.[messageName]?.postMessage(message);
}

// h5 跳转页面 && app 里面打开h5页面
function appOpenPage({ url, router, openNew }) {
  if (getIsInApp()) {
    const params = {
      appUrlStrKey: url,
    };
    if (openNew) {
      factoryAppFun('appOpenNewPage', params);
    } else {
      createLink(url);
    }
  } else {
    router?.push?.(url);
  }
}
// h5 跳转到 APP 页面
function openAppPage(params) {
  if (getIsInApp()) {
    factoryAppFun('openAppPage', params);
  }
}

// APP里面打开第三方链接
function appOpenLink(params) {
  if (!getIsInApp()) return;
  factoryAppFun('appOpenExternalLink', params);
}

// app go back
function appGoBack(router) {
  if (getIsInApp()) {
    factoryAppFun('appGoBack', '');
  } else {
    router?.back();
  }
}

function appQuotationPreview(params) {
  if (getIsInApp()) {
    factoryAppFun('appQuotationPreview', params);
  }
}

// web端
export const webBaseUrl = {
  seller: {
    dev: 'https://d-distribution.easyview.xyz',
    fat: 'https://t-distribution.easyview.xyz',
    uat: 'https://u.distribution.easyview.xyz',
    sim: 'https://hu-distribution.easyview.xyz',
    aws: 'https://distribution.easyviewsz.com',
    production: 'https://distribution.goldhorse.com.hk',
  },
  buyer: {
    dev: 'https://d-extramile.easyview.xyz',
    fat: 'https://t-extramile.easyview.xyz',
    uat: 'https://u.extramile.easyview.xyz',
    sim: 'https://hu-extramile.easyview.xyz',
    aws: 'https://extramile.easyviewsz.com',
    production: 'https://extramile.goldhorse.com.hk',
  },
};

// share
const shareBaseUrl = {
  seller: {
    dev: 'https://d-distribution.easyview.xyz/m', // dev
    fat: 'https://t-distribution.easyview.xyz/m', // fat
    uat: 'https://u.distribution.easyview.xyz/m', // uat
    sim: 'https://hu-distribution.easyview.xyz/m', // uat
    aws: 'https://distribution.easyviewsz.com/m', //aws
    production: 'https://distribution.goldhorse.com.hk/m', // production
  },
  buyer: {
    dev: 'https://d-extramile.easyview.xyz/m', // dev
    fat: 'https://t-extramile.easyview.xyz/m', // fat
    uat: 'https://u.extramile.easyview.xyz/m', // uat
    sim: 'https://hu-extramile.easyview.xyz/m', // uat
    aws: 'https://extramile.easyviewsz.com/m', //aws
    production: 'https://m.extramile.goldhorse.com.hk/m', // production
  },
};
const pagePaths = {
  'popular-detail': '/product',
  'quotation-price': '/product',
  'news-detail': '/news',
  'invite-register': '/inviteRegister',
  'market-detail': '/market/detail',
  'community-detail': '/community/detail',
};
// __type: news-detail, popular-detail, quotation-price, market-detail
// shareChannel [微信,朋友圈,站内聊天,复制链接,更多] [0,-1,-1,-1,-1] 表示只有微信这个分享 （如果不传默认全部）
function appShare(data = {}) {
  if (getIsInApp()) {
    const { shareUrl, __type, __copyLinkParams, ...extra } = data;
    if (__type) {
      if (shareUrl) {
        extra.shareLink = shareUrl;
      } else {
        const isSeller = getIsSeller();
        const baseUrl = shareBaseUrl[isSeller ? 'seller' : 'buyer'][getEnv()];
        const path = pagePaths[__type];
        const params = __copyLinkParams ? `?${__copyLinkParams}` : '';
        // eg: https://t-extramile.easyview.xyz/m/community/detail?id=51
        const shareLink = baseUrl + path + params;
        extra.shareLink = shareLink;
      }
    }
    // 部分字段太大了，微信最多支持1kb，这里简单做截取
    const sliceKey = ['title', 'descText'];
    sliceKey.forEach((key) => {
      extra[key] = extra[key]?.slice(0, 100);
    });

    factoryAppFun('appSocialShare', extra);
  }
}

function appTabBar(params = {}) {
  if (getIsInApp()) {
    params.value = JSON.stringify(params.value);
    factoryAppFun('appTabBar', params);
  }
}

// save app data
// params: {key, value}
function saveAppData(params = {}) {
  if (getIsInApp()) {
    params.value = JSON.stringify(params.value);
    factoryAppFun('appSetCacheData', params);
  } else {
    const { key, value } = params;
    saveLocaleStore(key, value);
  }
}

// get app data
// params: {key}
function getAppData(params = {}) {
  if (getIsInApp()) {
    if (isInAndroid()) {
      factoryAppFun('androidGetCacheData', params);
    } else {
      factoryAppFun('appGetCacheData', params);
    }
  } else {
    return getLocaleStore(params.key);
  }
}

// send email
function appSendEmail(url) {
  if (getIsInApp()) {
    const params = {
      emailUrl: url,
    };
    factoryAppFun('appSendEmail', params);
  } else {
  }
}

// copy
function appCopy(content) {
  if (getIsInApp()) {
    const params = {
      content,
    };
    factoryAppFun('appCopy', params);
  } else {
  }
}

// app telephone
function appTel(url) {
  if (getIsInApp()) {
    const params = {
      callUrl: url,
    };
    factoryAppFun('appCall', params);
  } else {
  }
}

// app title
function appTitle(title) {
  if (getIsInApp()) {
    const params = {
      title,
    };
    factoryAppFun('appTitle', params);
  }
}

// app open native page
function appOpenNativePage(pageScheme) {
  if (!pageScheme || !getIsInApp()) return;
  const params = {
    appUrlStrKey: pageScheme,
  };
  factoryAppFun('appOpenNativePage', params);
}

// app show token modal
function appOpenTokenModal(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appTokenInvalue', params);
}
function appOpenDocument(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appOpenDocument', params);
}
function appUpdateVersion(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appUpdateVersion', params);
}
function appSystemUpgrad(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appSystemUpgrad', params);
}

function appVisibility(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appVisibility', params);
}

function appLcmPreview(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appLcmPreview', params);
}

function lcmShowChange(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('lcmShowChange', params);
}

function lcmOnSave(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('lcmOnSave', params);
}

function appLcmBase64(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appLcmBase64', params);
}

// 上传图片 获取图片用appGetSocialImage
function appSocialUploadImage(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appSocialUploadImage', params);
}

// 协议弹框
function appSocialProtocolDialog(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appSocialProtocolDialog', params);
}

// 个人用户购买订阅pro
function appBuyProduct(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appBuyProduct', params);
}
// 外部浏览器打开h5
function appOpenBrowser(params = {}) {
  if (!getIsInApp()) return;
  factoryAppFun('appOpenBrowser', params);
}

export {
  appCopy,
  appGoBack,
  appOpenDocument,
  appOpenNativePage,
  appOpenPage,
  appOpenLink,
  appOpenTokenModal,
  appQuotationPreview,
  appSendEmail,
  appShare,
  appTabBar,
  appVisibility,
  appSystemUpgrad,
  appTel,
  appTitle,
  appUpdateVersion,
  callBridge,
  getAppData,
  isInAndroid,
  isInApp,
  isInFlutter,
  isPublicInAndroid,
  postAppMessages,
  register,
  saveAppData,
  unRegister,
  appLcmPreview,
  lcmShowChange,
  lcmOnSave,
  appLcmBase64,
  appSocialUploadImage,
  appSocialProtocolDialog,
  appBuyProduct,
  appOpenBrowser,
  openAppPage,
};
